exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-commercial-js": () => import("./../../../src/pages/work/commercial.js" /* webpackChunkName: "component---src-pages-work-commercial-js" */),
  "component---src-pages-work-narrative-js": () => import("./../../../src/pages/work/narrative.js" /* webpackChunkName: "component---src-pages-work-narrative-js" */),
  "component---src-pages-work-sanity-post-slug-current-js": () => import("./../../../src/pages/work/{sanityPost.slug__current}.js" /* webpackChunkName: "component---src-pages-work-sanity-post-slug-current-js" */)
}

